import { IKeyValue } from './breast-cancer-calc-interfaces';
export const historicalGrade = [
  '',
  'I',
  'II',
  'III'
];

export const posAndNegList = [
  'EMPTY',
  'POSITIVE',
  'NEGATIVE'
];

export const KI67 = [
  '',
  '≥20%',
  '<20%'
];


export const stages = [
  '', '0', 'IA', 'IB', 'IIA', 'IIB', 'IIC', 'IIIA', 'IIIB', 'IIIC', 'IV'
];

export const molecularTypeList = [
  'LA',
  'LB',
  'HER2',
  'BASAL',
  'CLAUDIN',
  'NOTAVAILABLE',
];
export const histologyTypeList = [
  'DINSITU',
  'DINFILTRATING',
  'DTUBULAR',
  'DMUCINOUS',
  'DMEDULLARY',
  'LINSITU',
  'LINFILTRATING',
  'NOTAVAILABLE',
];

export const metastesesOptions: IKeyValue[] = [
  {
    key: '',
    value: ''
  },
  {
    key: 'M0',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.M.VALUES.M0'
  },
  {
    key: 'M1',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.M.VALUES.M1'
  }
];

export const nodalInvolvementOptions: IKeyValue[] = [
  {
    key: '',
    value: ''
  },
  {
    key: 'N0',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.N.VALUES.N0'
  },
  {
    key: 'N1',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.N.VALUES.N1'
  },
  {
    key: 'N2',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.N.VALUES.N2'
  },
  {
    key: 'N3',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.N.VALUES.N3'
  },
  {
    key: 'Nx',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.N.VALUES.Nx'
  }
];
export const breastCancerTNM: IKeyValue[] = [
  {
    key: '',
    value: ''
  },
  {
    key: 'Tis',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.T.VALUES.Tis'
  },
  {
    key: 'T1',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.T.VALUES.T1'
  },
  {
    key: 'T2',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.T.VALUES.T2'
  },
  {
    key: 'T3',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.T.VALUES.T3'
  },
  {
    key: 'T4',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.T.VALUES.T4'
  },
  {
    key: 'Tx',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM.T.VALUES.Tx'
  }
];
