/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable, of } from 'rxjs';

import { BreastCancerInput } from '../model/breastCancer/breastcancerInput';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

import { environment } from '@environments/environment';
import { BreastCancer } from '../model/breastCancer/breastcancer';
import { BreastCancerCalculatedStage } from '../model/breastCancer/breastcancerCalculatedStage';
import { BreastCancerStageCalcBody } from '../model/breastCancer/breastCancerStageCalcBody';

@Injectable({ providedIn: 'root' })
export class BreastCancerService {
  protected basePath = environment.apiUrl + environment.apiEnv;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Devuelve el cálculo de la etapa
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public breastCancerStageCalcPost(body: BreastCancerStageCalcBody, observe?: 'body', reportProgress?: boolean): Observable<BreastCancerCalculatedStage>;
  public breastCancerStageCalcPost(body: BreastCancerStageCalcBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BreastCancerCalculatedStage>>;
  public breastCancerStageCalcPost(body: BreastCancerStageCalcBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BreastCancerCalculatedStage>>;
  public breastCancerStageCalcPost(body: BreastCancerStageCalcBody, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling breastCancerStageCalcPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<BreastCancerCalculatedStage>('post', `${this.basePath}/breastCancerStageCalc`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
    // return this.httpClient.request<BreastCancer>('post', `${this.basePath}/breastcancer`, {
    //   body: body,
    //   withCredentials: this.configuration.withCredentials,
    //   headers: headers,
    //   observe: observe,
    //   reportProgress: reportProgress
    // }););
    // return this.httpClient.request<BreastCancer>('post', `${this.basePath}/breastcancer`, {
    //   body: body,
    //   withCredentials: this.configuration.withCredentials,
    //   headers: headers,
    //   observe: observe,
    //   reportProgress: reportProgress
    // });
  }

  /**
   *
   * Breast Cancer
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public breastCancerPost(body: BreastCancerInput, observe?: 'body', reportProgress?: boolean): Observable<BreastCancer>;
  public breastCancerPost(body: BreastCancerInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BreastCancer>>;
  public breastCancerPost(body: BreastCancerInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BreastCancer>>;
  public breastCancerPost(body: BreastCancerInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling breastCancerPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }
    return this.httpClient.request<BreastCancer>('post', `${this.basePath}/breastCancer`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });

  }
}
