import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ShoppingCartService } from '@app/core/services/shopping-cart/shopping-cart.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breast-cancer-calc-result',
  templateUrl: './breast-cancer-calc-result.component.html',
  styleUrls: ['./breast-cancer-calc-result.component.scss']
})
export class BreastCancerCalcResultComponent implements OnInit, OnDestroy {
  @Input() dataSource: { data: any; result: any };
  @Output() dataToEdit: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  dataResult: any;
  constructor(private translateSrv: TranslateService, private cartShoppingSrv: ShoppingCartService) { }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // console.log('this.dataSource: ', this.dataSource);
    this.prepareResultData();
    this.subscription = this.cartShoppingSrv.get().subscribe((cart) => {
      cart.items.forEach((item) => {
        if (item.id.indexOf('CALC_BC') !== -1) {
          item.dataCalc = this.dataSource.data;
        }
      });
      this.cartShoppingSrv.save(cart);
    });
  }

  prepareResultData = (): void => {

    let values = [
      {
        id: this.dataSource.result.id,
        values: ['', 'TITLES.BREAST-CANCER-CALCULATOR']
      }
    ];

    if (this.dataSource.result.immunotherapy) {
      values = [
        {
          id: 'NO_CART',
          values: ['', 'TITLES.BREAST-CANCER-CALCULATOR']
        },
        {
          id: 'NO_CART',
          values: ['', 'TITLES.IMMUNOTHERAPY']
        },
        {
          id: this.dataSource.result.id,
          values: ['', 'TITLES.FINAL_RESULT']
        }
      ];
    }
    this.dataResult = {
      stickyColsStart: 2,
      stickyColsEnd: 1,
      headerValues: [' ', 'RATE.TTL-HEADER-RISK'],
      values: [...values]
    };

    if (this.dataSource.result.immunotherapy) {
      this.dataSource.result.breast_cancer.map((e: any) => {
        this.dataResult.headerValues.push(this.translateSrv.instant(`RATE.COBERTURAS.${e.id}`));
        this.dataResult.values[0].values.push(e.valor);
      });

      this.dataSource.result.immunotherapy.map((e: any) => {
        this.dataResult.values[1].values.push(e.valor);
      });

      this.dataSource.result.results.map((e: any) => {
        this.dataResult.values[2].values.push(e.valor);
      });
    } else {
      this.dataSource.result.results.map((e: any) => {
        this.dataResult.headerValues.push(this.translateSrv.instant(`RATE.COBERTURAS.${e.id}`));
        this.dataResult.values[0].values.push(e.valor);
      });
    }

  };

  hasTreatment = (treatment: string): boolean =>
    !!this.dataSource.data.treatmentType[treatment];

  getSurgicalTreatment = () =>
    this.translateSrv.instant(
      `MELANOMA-CALC.TREATMENT-TYPE.SURGICAL${this.dataSource.data.treatmentType.surgical.exeresis === 0 ? '.TOTAL' : '.PARTIAL'}`);

  getInCourseLabel = (treatment: string, option?: string): string | void => {
    if (option) {
      return this.translateSrv.instant(
        `BREAST-CANCER-CALC.TREATMENT-TYPE.GENERAL.${this.dataSource.data.treatmentType[treatment][option] ? 'YES' : 'NO'}`);
    }
    if (this.dataSource.data.treatmentType[treatment].inCourse) {
      return this.translateSrv.instant(
        `BREAST-CANCER-CALC.TREATMENT-TYPE.GENERAL.${this.dataSource.data.treatmentType[treatment].inCourse ? 'YES' : 'NO'}`);
    }
  };

  getMedicalTreatmentState = (medicalTreatment: any): string | void =>
    this.translateSrv.instant(`BREAST-CANCER-CALC.TREATMENT-TYPE.GENERAL.${medicalTreatment.state ? 'LABEL-INCOURSE' : 'LABEL-FINALIZED'}`);

  backToEdit = (): void => {
    this.dataToEdit.emit(this.dataSource.data);
  };
}
